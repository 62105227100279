import { css } from 'styled-components'
import { rgba } from 'polished'

import { black, white } from 'styles/colors'
import { borderRadius } from 'styles/variables'

export default css`
  .tooltip {
    background-color: ${rgba(black, 0.92)} !important;
    border-radius: ${borderRadius} !important;
    max-width: 230px;
    color: ${white} !important;
    text-align: center;
  }

  .tooltip.place-top:after {
    border-top-color: ${rgba(black, 0.9)} !important;
  }
`
