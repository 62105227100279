import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { stripUnit, rgba } from 'polished'

import { primaryColor } from 'styles/colors'
import { largerThan } from 'styles/media'
import { fontWeights, fontSizes, lineHeights } from 'styles/typography'
import { verticalPadding } from 'styles/helpers'
import { animationTimes, animationCurve, borderRadius } from 'styles/variables'
import * as spacing from 'styles/spacing'

import SmallCaps from 'components/SmallCaps'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import SocialLinks from 'components/SocialLinks'
import Heading from 'components/Heading'
import Button from 'components/button/Button'
import { Grid, GridRow, GridColumn } from 'components/Grid'

const currentYear = new Date().getFullYear()

const Container = styled.footer`
  background-color: ${({ theme }) => theme.background};
  border-top: 1px solid ${({ theme }) => theme.border};
`

const ColumnHeading = styled(Heading)`
  display: block;
  margin-bottom: ${spacing.small};

  ${largerThan.medium`
    margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'rem'};
  `};
`

const FooterLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.body};
  transition: color ${animationTimes.base} ${animationCurve};
  gap: ${spacing.small};
  line-height: 1.75;

  ${largerThan.medium`
    line-height: 2;
  `};

  &:hover {
    color: ${({ theme }) => theme.heading};
  }
`

const More = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.border};
  ${verticalPadding(spacing.large)};

  ${largerThan.small`
    ${verticalPadding(spacing.xLarge)};
  `};

  ${largerThan.medium`
    ${verticalPadding(spacing.xxLarge)};
  `};

  ${largerThan.large`
    ${verticalPadding(spacing.xxxLarge)};
  `};
`

const Action = styled.div`
  margin-bottom: ${spacing.medium};

  ${largerThan.small`
    margin-bottom: ${spacing.large};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `};

  ${largerThan.medium`
    margin-bottom: ${spacing.large};
    display: block;
  `};
`

const Copyright = styled.span`
  display: block;
  color: ${({ theme }) => theme.muted};
  font-size: 0.9em;
`

const Tag = styled.div`
  color: ${primaryColor};
  font-weight: ${fontWeights.medium};
  padding: ${spacing.scale(spacing.tiny, 0.5)}
    ${spacing.scale(spacing.small, 0.75)};
  color: ${primaryColor};
  border-radius: ${spacing.scale(borderRadius, 0.5)};
  background-color: ${rgba(primaryColor, 0.15)};
  transition: background-color ${animationTimes.base} ${animationCurve};
  font-size: ${fontSizes.tiny};
  line-height: ${lineHeights.base};
`

const Footer = () => (
  <Container>
    <Wrapper>
      <Section>
        <Grid>
          <GridRow>
            <GridColumn xs={12} md={5}>
              <Action>
                <Heading size={2}>
                  Got a project? <br />
                  Let's talk
                </Heading>
                <Button to="/contact/">Contact us</Button>
              </Action>
            </GridColumn>
            <GridColumn xs={12} md={7}>
              <Grid>
                <GridRow>
                  <GridColumn xs={6} sm={4} md={4}>
                    <ColumnHeading size={6} tag="span">
                      Company
                    </ColumnHeading>
                    <FooterLink to="/work/">Work</FooterLink>
                    <FooterLink to="/about/">About</FooterLink>
                    <FooterLink to="/careers/">
                      Careers<Tag>Hiring!</Tag>
                    </FooterLink>
                    <FooterLink to="/contact/">Contact</FooterLink>
                  </GridColumn>
                  <GridColumn xs={6} sm={4} md={4}>
                    <ColumnHeading size={6} tag="span">
                      Services
                    </ColumnHeading>
                    <FooterLink to="/services/strategy/">Strategy</FooterLink>
                    <FooterLink to="/services/design/">Design</FooterLink>
                    <FooterLink to="/services/development/">
                      Development
                    </FooterLink>
                  </GridColumn>
                  <GridColumn xs={12} sm={4} md={4}>
                    <ColumnHeading size={6} tag="span">
                      Address
                    </ColumnHeading>
                    <FooterLink
                      as="a"
                      href="https://www.google.com/maps/place/WeWork+Office+Space+%26+Coworking/@45.5013553,-73.5730028,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc91a446206288b:0x39225c95c87b6c34!8m2!3d45.501171!4d-73.5709889"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 Place Ville Marie #400
                      <br />
                      Montreal, QC
                      <br />
                      H3B 2E3 Canada
                    </FooterLink>
                  </GridColumn>
                </GridRow>
              </Grid>
            </GridColumn>
          </GridRow>
        </Grid>
      </Section>
    </Wrapper>
    <Wrapper>
      <More>
        <Copyright>© {currentYear} Volume7 Inc.</Copyright>
        <SocialLinks />
      </More>
    </Wrapper>
  </Container>
)

export default Footer
