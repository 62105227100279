import React from 'react'
import styled, { css } from 'styled-components'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${largerThan.small`
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  `};

  ${largerThan.large`
    padding-left: 10vw;
    padding-right: 10vw;
  `};
`

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;

  ${(props) =>
    props.size === 'narrow' &&
    css`
      max-width: 860px;
    `}
`

const Wrapper = ({ children, size }) => (
  <Container>
    <Inner size={size}>{children}</Inner>
  </Container>
)

export default Wrapper
