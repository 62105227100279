import { stripUnit, mix, rgba } from 'polished'

import { breakpoints } from 'styles/media'
import {
  grey,
  greyLight,
  black,
  greyDark,
  borderColor,
  white,
  offWhite,
} from 'styles/colors'
import * as spacing from 'styles/spacing'

export const themes = {
  light: {
    heading: greyDark,
    body: grey,
    background: '#fff',
    action: white,
    border: borderColor,
    muted: greyLight,
    input: rgba(borderColor, 0.35),
    accent: offWhite,
  },
  dark: {
    heading: white,
    body: rgba(white, 0.7),
    background: '#222',
    action: mix(0.05, white, greyDark),
    border: rgba(white, 0.1),
    muted: rgba(white, 0.6),
    input: rgba(black, 0.15),
    accent: rgba(white, 0.05),
  },
}

export const customGridConfig = {
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  gutterWidth: {
    xs: stripUnit(spacing.medium),
    sm: stripUnit(spacing.large),
    md: stripUnit(spacing.large),
    lg: stripUnit(spacing.xLarge),
    xl: stripUnit(spacing.xLarge),
  },
  breakpoints: {
    xs: 1,
    sm: stripUnit(breakpoints.small),
    md: stripUnit(breakpoints.medium),
    lg: stripUnit(breakpoints.large),
    xl: stripUnit(breakpoints.xLarge),
  },
}
