import React from 'react'

import Bulb from 'assets/icons/strategy.svg'
import Design from 'assets/icons/design.svg'
import Code from 'assets/icons/code.svg'

import Aldo from 'assets/images/clients/aldo.svg'
import YellowPages from 'assets/images/clients/yp.svg'
import Affirm from 'assets/images/clients/affirm.svg'
import Peach from 'assets/images/clients/peach.svg'
import Bell from 'assets/images/clients/bell.svg'
import Consensys from 'assets/images/clients/consensys.svg'
import Salesforce from 'assets/images/clients/salesforce.svg'
import Beamery from 'assets/images/clients/beamery.svg'
import Plenty from 'assets/images/clients/plenty.svg'
import Desjardins from 'assets/images/clients/desjardins.svg'
import QBio from 'assets/images/clients/q.svg'
import Truework from 'assets/images/clients/truework.svg'
import Walter from 'assets/images/clients/usewalter.svg'
import Zendesk from 'assets/images/clients/zendesk.svg'
import Seatgeek from 'assets/images/clients/seatgeek.svg'
import CreditKarma from 'assets/images/clients/creditkarma.svg'
import SentiLink from 'assets/images/clients/sentilink.svg'
import BAT from 'assets/images/clients/bat.svg'
import Vape from 'assets/images/clients/vape.svg'
import CPG from 'assets/images/clients/cpg.svg'
import Cushion from 'assets/images/clients/cushion.svg'
import Arc from 'assets/images/clients/arc.svg'
import Titan from 'assets/images/clients/titan.svg'
import Prospect from 'assets/images/clients/prospect.svg'

export const INFO_FOR_PROJECT_NAME = {
  aldo: {
    color: '#000',
    slug: 'aldo',
    industry: 'Retail',
    heading:
      'Rejuvenating a half-century-old fashion retailer using technology',
    description: (
      <>
        Multinational fashion retailer <strong>Aldo</strong> selected us to be
        their digital innovation partner of choice, helping them build a number
        of business-critical applications.
      </>
    ),
    link: 'https://www.aldoshoes.com/',
    logo: <Aldo />,
    logoWidth: 113,
    year: '2016 – 2018',
    figure: '/images/work/thumbnails/aldo.jpg',
  },
  'yellow-pages': {
    color: '#ffcd00',
    slug: 'yellow-pages',
    heading: 'Reimagining what content consumption could look like',
    description: (
      <>
        Digital media leader <strong>Yellow Pages</strong> tasked us with
        imagining what a next-gen content platform could look like, and provide
        them with a component library for rapid implementation.
      </>
    ),
    link: 'https://www.yellowpages.ca/',
    logo: <YellowPages />,
    logoWidth: 174,
    figure: '/images/work/thumbnails/yellow-pages.jpg',
    year: '2016',
    comingSoon: true,
  },
  salesforce: {
    logo: <Salesforce />,
    logoWidth: 82,
    link: 'https://www.salesforce.com/',
  },
  creditkarma: {
    logo: <CreditKarma />,
    logoWidth: 160,
    color: '#008602',
    heading: 'Financial products for every step of your journey',
    description: (
      <>
        We helped personal finance company <strong>Credit Karma</strong> expand
        their product offering by conceptualizing a new mobile financial
        dashboard.
      </>
    ),
    link: 'https://www.creditkarma.com/',
    year: '2017',
  },
  bat: {
    logo: <BAT />,
    logoWidth: 108,
    link: 'https://www.bat.com/',
  },
  arc: {
    logo: <Arc />,
    logoWidth: 70,
    color: '#000',
    heading: 'Reinventing fundraising for startups through technology',
    description: (
      <>
        We worked with fintech startup <strong>Arc</strong> to help launch and
        grow their digital banking platform made specifically for SaaS
        companies.
      </>
    ),
    link: 'https://www.arc.tech/',
    year: '2021 - Present',
  },
  prospect: {
    logo: <Prospect />,
    logoWidth: 150,
    color: '#047857',
    heading: 'Find startups worth betting your career on',
    description: (
      <>
        We acted as external design partner for job search startup{' '}
        <strong>Prospect</strong>, leading all design and front-end efforts.
      </>
    ),
    link: 'https://www.joinprospect.com/',
  },
  sentilink: {
    logo: <SentiLink />,
    logoWidth: 154,
    color: '#ed1c24',
    heading: 'Building the future of identity verification',
    description: (
      <>
        We helped <strong>SentiLink</strong> become the definitive fraud
        prevention solution by creating a bespoke brand identity, marketing
        website, and product design system.
      </>
    ),
    link: 'https://www.sentilink.com/',
    year: '2017',
  },
  titan: {
    logo: <Titan />,
    logoWidth: 130,
    color: '#111',
    heading: 'A new kind of wealth management platform',
    description: (
      <>
        Wealth management platform <strong>Titan</strong> sought us out to help
        them with design initiatives, spanning from web marketing to product.
      </>
    ),
    link: 'https://www.titan.com/',
  },
  usewalter: {
    color: '#B8A279',
    slug: 'usewalter',
    industry: 'Real estate',
    heading:
      'Supporting a rapid-growth startup for velocity and geographic expansion',
    description: (
      <>
        We provided our deep design and development expertise to{' '}
        <strong>Usewalter</strong>, a proptech company offering smart building
        technology.
      </>
    ),
    link: 'https://www.usewalter.com/',
    logo: <Walter />,
    logoWidth: 154,
    year: '2020 – 2021',
    figure: '/images/work/thumbnails/usewalter.jpg',
  },
  cpg: {
    color: '#1C2755',
    slug: 'cpg',
    industry: 'Retail',
    heading:
      'Mobilizing a global sales force while enabling better decision-making',
    description: (
      <>
        We developed a mobile-optimized web app for use by{' '}
        <strong>one of the world's largest CPG brands</strong> to track,
        analyze, and optimize sales.
      </>
    ),
    logo: <CPG />,
    logoWidth: 124,
    year: '2017 – Present',
    figure: '/images/work/thumbnails/cpg.jpg',
  },
  vape: {
    color: '#000',
    slug: 'vape',
    industry: 'Retail',
    heading: 'Building and scaling a best-in-class DTC online store',
    description: (
      <>
        We helped <strong>one of the world's largest tobacco companies</strong>{' '}
        launch a direct-to-consumer online store for their flagship vaping
        brand.
      </>
    ),
    logo: <Vape />,
    logoWidth: 150,
    year: '2019 – 2021',
    figure: '/images/work/thumbnails/vape.jpg',
  },
  affirm: {
    color: '#4a4af4',
    logo: <Affirm />,
    logoWidth: 100,
    heading: "Augmenting a fintech company's design team",
    description: (
      <>
        We partnered with buy-now-pay-later leader <strong>Affirm</strong> to
        support their design initiatives, from startup phase all the way to an
        enormously successful IPO.
      </>
    ),
    link: 'https://www.affirm.com',
    year: '2016 – 2021',
    figure: '/images/work/thumbnails/affirm.jpg',
    comingSoon: true,
  },
  consensys: {
    color: '#000',
    logo: <Consensys />,
    logoWidth: 156,
    heading: 'Web3 technology for recreation and leisure',
    description: (
      <>
        Web3 consultancy <strong>ConsenSys</strong> enlisted our help to build
        one of the world's first Ethereum-based collectibles platform.
      </>
    ),
    link: 'https://consensys.net/',
    figure: '/images/work/thumbnails/consensys.jpg',
    comingSoon: true,
  },
  plenty: {
    color: '#ff391b',
    logo: <Plenty />,
    logoWidth: 101,
    heading: 'Democratizing a new way to grow crops',
    description: (
      <>
        Indoor vertical farming company <strong>Plenty</strong> approached us to
        build their inaugural website, which helped cement their place as a
        leader in the agtech space.
      </>
    ),
    link: 'https://www.plenty.ag/',
  },
  cushion: {
    color: '#1fc2c1',
    logo: <Cushion />,
    logoWidth: 144,
    heading: 'Using AI to negotiate unfair bank fees',
    description: (
      <>
        Fintech startup <strong>Cushion</strong> asked us to design and build
        the first version of the popular AI credit card and bank fee negotiation
        tool.
      </>
    ),
    link: 'https://cushion.ai/',
  },
  desjardins: {
    color: '#047844',
    logo: <Desjardins />,
    logoWidth: 166,
    heading: 'Providing support for aspiring entrepreneurs',
    description: (
      <>
        North America's largest credit union <strong>Desjardins</strong>{' '}
        entrusted us with digitizing their mentoring and financing program aimed
        at aspiring entrepreneurs.
      </>
    ),
    link: 'https://www.desjardins.com/ca/',
    year: '2016 – 2019',
    figure: '/images/work/thumbnails/desjardins.jpg',
    comingSoon: true,
  },
  beamery: {
    color: '#F15852',
    logo: <Beamery />,
    logoWidth: 160,
    heading: 'A new kind of talent management software',
    description: (
      <>
        We worked with talent acquisition software company{' '}
        <strong>Beamery</strong> to design a new marketing website, allowing
        them to stand out from the competition while increasing inbound leads.
      </>
    ),
    link: 'https://beamery.com/',
  },
  seatgeek: {
    color: '#ff5b49',
    logo: <Seatgeek />,
    logoWidth: 120,
    heading: 'Providing support for aspiring entrepreneurs',
    description: (
      <>
        North America's largest credit union <strong>Desjardins</strong>{' '}
        entrusted us with digitizing their mentoring and financing program aimed
        at aspiring entrepreneurs.
      </>
    ),
    link: 'https://seatgeek.com/',
    figure: '/images/work/thumbnails/seatgeek.jpg',
    comingSoon: true,
  },
  bell: {
    color: '#00549a',
    logo: <Bell />,
    logoWidth: 60,
    heading: 'Gathering invaluable customer information',
    description: (
      <>
        We collaborated with communications giant <strong>Bell</strong> to build
        an in-store data collection experience, enabling them to gain invaluable
        insights on their customers.
      </>
    ),
    link: 'https://www.bell.ca/',
  },
  peach: {
    color: '#de7258',
    logo: <Peach />,
    logoWidth: 134,
    heading: 'Infrastructure for tomorrow’s lending products',
    description: (
      <>
        We helped lending software startup <strong>Peach</strong> launch their
        proprietary loan management system with a new brand and website.
      </>
    ),
    link: 'https://www.peachfinance.com/',
  },
  qbio: {
    color: '#0869e9',
    logo: <QBio />,
    logoWidth: 90,
    heading: 'Launching the first clinical Digital Twin platform',
    description: (
      <>
        We helped <strong>Q Bio</strong> launch their revolutionary clinical
        platform to the world, through an interactive website backed by a
        powerful CMS.
      </>
    ),
    link: 'https://q.bio/',
    figure: '/images/work/thumbnails/qbio.jpg',
    comingSoon: true,
  },
  truework: {
    color: '#5a64fe',
    logo: <Truework />,
    logoWidth: 165,
    heading: 'Income and employment verification made easy',
    description: (
      <>
        We provided design and development services to identity verification
        startup <strong>Truework</strong>, helping them bring their innovative
        new product to market and scale more efficiently.
      </>
    ),
    link: 'https://www.truework.com/',
  },
  zendesk: {
    color: '#17494d',
    logo: <Zendesk />,
    logoWidth: 130,
    link: 'https://www.zendesk.com/',
  },
}

export const INDUSTRIES = [
  {
    name: 'Finance',
    slug: 'finance',
    photo: '/images/industries/finance.jpg',
  },
  {
    name: 'Retail',
    slug: 'retail',
    photo: '/images/industries/retail.jpg',
  },
  {
    name: 'Web3',
    slug: 'web3',
    photo: '/images/industries/blockchain.jpg',
  },
  {
    name: 'Real estate',
    slug: 'real-estate',
    photo: '/images/industries/real-estate.jpg',
  },
  {
    name: 'IoT',
    slug: 'iot',
    photo: '/images/industries/iot.jpg',
  },
  {
    name: 'Healthcare',
    slug: 'healthcare',
    photo: '/images/industries/healthcare.jpg',
  },
]

export const SERVICES = [
  {
    name: 'Strategy',
    slug: 'strategy',
    description:
      'We help you define business goals, identify success metrics, and establish a clear vision for your product and its roadmap.',
    list: ['Business strategy', 'Project management', 'Testing', 'Analytics'],
    icon: <Bulb />,
    figure: '/images/services/strategy.jpg',
  },
  {
    name: 'Design',
    slug: 'design',
    description:
      "We design user experiences that are intuitive and elegant, and translate them into working code that's pixel-perfect.",
    list: [
      'User experience (UX) design',
      'User interface (UI) design',
      'Web design',
      'Branding',
      'Data visualization',
    ],
    icon: <Design />,
    figure: '/images/services/design.jpg',
  },
  {
    name: 'Development',
    slug: 'development',
    description:
      "Our bread and butter. We use the latest technologies to build quality software that's fast, scalable, and secure.",
    list: [
      'Web development',
      'Mobile development',
      'Backend development',
      'Security',
      'Automated testing',
      'Digital transformation',
      'Cloud migration',
    ],
    icon: <Code />,
    figure: '/images/services/development.jpg',
  },
]
