import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const MetaTags = ({ title, description }) => (
  <StaticQuery
    query={graphql`
      query MetaTagsQuery {
        site {
          siteMetadata {
            siteUrl
            description
          }
        }
      }
    `}
    render={(data) => (
      <Helmet>
        {/* Title + description */}
        <title>{title}</title>
        <meta
          name="description"
          content={description || data.site.siteMetadata.description}
          data-react-helmet="true"
        />

        {/* Facebook */}
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta
          property="og:description"
          content={description || data.site.siteMetadata.description}
          data-react-helmet="true"
        />
        <meta
          property="og:type"
          content="website"
          key="og:type"
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl}
          key="og:url"
        />
        <meta
          property="og:image"
          content={`${data.site.siteMetadata.siteUrl}/share-image.png`}
          key="og:image"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        {/* Twitter */}
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta
          name="twitter:url"
          content={data.site.siteMetadata.siteUrl}
          key="twitter:url"
        />
        <meta name="twitter:title" content={title} data-react-helmet="true" />
        <meta
          name="twitter:description"
          content={description || data.site.siteMetadata.description}
          data-react-helmet="true"
        />
        <meta
          name="twitter:image"
          content={`${data.site.siteMetadata.siteUrl}/share-image.png`}
          key="twitter:image"
        />
      </Helmet>
    )}
  />
)

export default MetaTags
