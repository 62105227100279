// Primary

export const red = '#f15e58'

// Greys

export const greyDark = '#222'
export const grey = '#555'
export const greyLight = '#999'
export const greyLightest = '#dedede'
export const offWhite = '#f7f7f7'

// Misc

export const white = '#fff'
export const black = '#000'
export const green = '#2BBC63'

// Set colors

export const primaryColor = red
export const borderColor = greyLightest
