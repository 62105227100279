import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { stripUnit } from 'polished'
import * as Collapsible from '@radix-ui/react-collapsible'
import { motion, AnimatePresence } from 'framer-motion'
import { camelCase } from 'lodash'

import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { animationCurve, animationTimes } from 'styles/variables'

const DURATION = stripUnit(animationTimes.base)

const variants = {
  collapsed: {
    opacity: 0,
    height: 0,
    x: '-10',
    transition: { duration: DURATION },
    ease: camelCase(animationCurve),
  },
  open: {
    opacity: 1,
    height: 'auto',
    x: 0,
    transition: { duration: DURATION },
    ease: camelCase(animationCurve),
  },
}

const Container = styled(motion.div)`
  overflow: hidden;
  padding-left: ${spacing.scale(spacing.small, 1.5)};
`

const Item = styled(Link)`
  display: flex;
  width: 100%;
  padding: ${spacing.tiny} 0;
  font-weight: ${fontWeights.regular};
  color: ${({ theme }) => theme.body};

  &${'.active'} {
    color: ${({ theme }) => theme.heading};
  }

  &:first-child {
    padding-top: ${spacing.scale(spacing.small, 1.5)};
  }
`

const Submenu = ({ sublinks, isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <Collapsible.Content forceMount asChild>
        <Container
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={variants}
          type="ease"
          transition={{ ease: animationCurve }}
        >
          {sublinks.map((sublink, i) => (
            <Item
              key={i}
              to={sublink.link}
              partiallyActive
              activeClassName="active"
            >
              {sublink.label}
            </Item>
          ))}
        </Container>
      </Collapsible.Content>
    )}
  </AnimatePresence>
)

export default Submenu
