import { stripUnit } from 'polished'

export const tiny = '0.25rem'
export const small = '0.5rem'
export const medium = '1rem'
export const large = '1.5rem'
export const xLarge = '2rem'
export const xxLarge = '2.5rem'
export const xxxLarge = '3.5rem'

export const scale = (spacing, factor) => {
  return `${stripUnit(spacing) * factor}rem`
}
