import React from 'react'
import styled, { css } from 'styled-components'

import {
  fontWeights,
  fontSizes,
  fontFamilies,
  lineHeights,
} from 'styles/typography'
import { largerThan } from 'styles/media'

// Scale: 56/40/32/24/20/14

const getFontSize = (size) => {
  if (size === 1)
    return css`
      font-size: 32px;
      line-height: 1;

      ${largerThan.small`
        font-size: 40px;
      `};

      ${largerThan.medium`
        font-size: 56px;
      `};

      ${largerThan.large`
        font-size: 64px;
      `};

      ${largerThan.xLarge`
        font-size: 72px;
      `};
    `

  if (size === 2)
    return css`
      font-size: 25px;
      line-height: 1.2;
      margin-bottom: 0.5em;

      ${largerThan.small`
        font-size: 28px;
      `};

      ${largerThan.medium`
        font-size: 32px;
      `};

      ${largerThan.large`
        font-size: 36px;
      `};

      ${largerThan.xLarge`
        font-size: 40px;
      `};
    `

  if (size === 3)
    return css`
      font-size: 22px;
      line-height: 1.25;
      margin-bottom: 0.5em;

      ${largerThan.small`
        font-size: 24px;
      `};

      ${largerThan.medium`
        font-size: 27px;
      `};

      ${largerThan.large`
        font-size: 29px;
      `};

      ${largerThan.xLarge`
        font-size: 32px;
      `};
    `

  if (size === 4)
    return css`
      font-size: 18px;
      line-height: 1.25;
      margin-bottom: 0.5em;

      ${largerThan.small`
        font-size: 19px;
      `};

      ${largerThan.medium`
        font-size: 21px;
      `};

      ${largerThan.large`
        font-size: 22px;
      `};

      ${largerThan.xLarge`
        font-size: 24px;
      `};
    `

  if (size === 5)
    return css`
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 0.5em;

      ${largerThan.small`
        font-size: 17px;
      `};

      ${largerThan.medium`
        font-size: 18px;
      `};

      ${largerThan.large`
        font-size: 19px;
      `};

      ${largerThan.xLarge`
        font-size: 20px;
      `};
    `

  if (size === 6)
    return css`
      font-size: ${fontSizes.tiny};
      text-transform: uppercase;
      letter-spacing: 0.075em;
      margin-bottom: 1em;

      ${largerThan.medium`
        font-size: ${fontSizes.small};
      `};
    `
}

const Container = styled.div`
  font-family: ${fontFamilies.heading};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.heading};
  color: ${({ theme }) => theme.heading};
  margin-bottom: 0.25em;
  display: block;
  letter-spacing: -0.015em;
  ${(props) => getFontSize(props.size)};

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = ({ size = 5, tag, ...props }) => (
  <Container as={tag || `h${size}`} size={size} {...props} />
)

export default Heading
