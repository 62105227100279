import { createGlobalStyle } from 'styled-components'

import Normalize from './normalize'
import Typography from './typography'
import Vendor from './vendor'

export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Typography};
  ${Vendor};

  body {
    background-color: ${({ theme }) => theme.background};
  }
`
