import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import * as Collapsible from '@radix-ui/react-collapsible'

import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontWeights } from 'styles/typography'
import { primaryColor } from 'styles/colors'
import { animationCurve, animationTimes } from 'styles/variables'

import Heading from 'components/Heading'
import Icon from 'components/Icon'
import Submenu from './Submenu'

import Chevron from 'assets/icons/chevron-down.svg'

const Container = styled(Link)`
  display: block;
  width: 100%;
  text-align: left;
  font-weight: ${fontWeights.medium};
  padding-top: ${spacing.small};
  padding-bottom: ${spacing.small};

  ${largerThan.small`
    padding-top: ${spacing.scale(spacing.small, 1.25)};
    padding-bottom: ${spacing.scale(spacing.small, 1.25)};
  `};
`

const Title = styled(Heading)`
  margin-bottom: 0;
`

const Trigger = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.heading};
`

const IconWrap = styled.div`
  display: flex;
  margin-left: ${spacing.tiny};
  transition: transform ${animationTimes.base} ${animationCurve};

  ${(props) =>
    props.flipped &&
    css`
      transform: rotate(180deg);
    `}
`

const Label = styled(Heading)`
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: ${spacing.scale(spacing.tiny, 0.5)};
    transform: scaleX(0);
    transform-origin: left;
    right: 0;
    height: 2px;
    background-color: ${primaryColor};
    transition: opacity ${animationTimes.base} ${animationCurve},
      transform ${animationTimes.base} ${animationCurve};
    opacity: 0;
  }

  ${Container}${'.active'} &:after {
    opacity: 1;
    transform: none;
  }
`

const MobileNavItem = ({ link, sublinks, label, location }) => {
  const [open, setOpen] = useState(false)

  if (sublinks) {
    const defaultOpen = location.pathname.includes(link)

    return (
      <Container as="div">
        <Collapsible.Root
          open={open}
          onOpenChange={setOpen}
          defaultOpen={defaultOpen}
          asChild
        >
          <>
            <Collapsible.Trigger asChild>
              <Trigger>
                <Title size={3}>{label}</Title>
                <IconWrap flipped={open}>
                  <Icon icon={<Chevron />} />
                </IconWrap>
              </Trigger>
            </Collapsible.Trigger>
            <Submenu sublinks={sublinks} isOpen={open} />
          </>
        </Collapsible.Root>
      </Container>
    )
  }

  return (
    <Container
      to={link}
      role="menuitem"
      partiallyActive
      activeClassName="active"
    >
      <Label size={3} as={Heading}>
        {label}
      </Label>
    </Container>
  )
}

export default MobileNavItem
