import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { mix } from 'polished'

import { themes } from 'styles/theme'
import { GlobalStyle } from 'styles/global'
import { pageWidth } from 'styles/variables'
import { black } from 'styles/colors'

import Nav from 'components/Nav'
import MetaTags from 'components/MetaTags'
import Footer from 'components/Footer'

const Page = styled.div`
  width: 100%;
  background-color: ${({ theme }) => mix(0.92, theme.background, black)};
`

const Inner = styled.div`
  width: 100%;
  max-width: ${pageWidth};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.background};
`

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider
      theme={location.pathname.includes('ai') ? themes.dark : themes.light}
    >
      <GlobalStyle />
      <MetaTags title="Custom software development in Montreal | Volume7" />
      <Page>
        <Inner>
          <Nav location={location} />
          <main>
            {children}
            <Footer />
          </main>
        </Inner>
      </Page>
    </ThemeProvider>
  )
}

export default Layout
