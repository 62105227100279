import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { largerThan, smallerThan } from 'styles/media'
import { primaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { verticalPadding } from 'styles/helpers'

import { SERVICES } from 'utils/constants'

import Wrapper from 'components/Wrapper'
import MobileNav from 'components/MobileNav'
import Button from 'components/button/Button'
import NavItem from './NavItem'

import Logo from 'assets/images/logo.svg'

const Container = styled.div`
  ${verticalPadding(spacing.large)}

  ${largerThan.small`
    ${verticalPadding(spacing.xLarge)};
  `};

  ${largerThan.medium`
    ${verticalPadding(spacing.xxLarge)};
  `};

  ${largerThan.xLarge`
    ${verticalPadding('3rem')};
  `};
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoWrap = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${spacing.small};
  margin: -${spacing.small};
`

const StyledLogo = styled(Logo)`
  width: 38px;
  height: 33px;
  fill: ${primaryColor};

  ${largerThan.small`
    width: 44px;
    height: 38px;
  `};

  ${largerThan.medium`
    width: 51px;
    height: 44px;
  `};

  ${largerThan.large`
    width: 56px;
    height: 49px;
  `};
`

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  ${smallerThan.medium`
    display: none;
  `};
`

const ListItem = styled.li`
  position: static;

  & + & {
    margin-left: ${spacing.xLarge};

    ${largerThan.xLarge`
      margin-left: ${spacing.xxLarge};
    `};
  }
`

const MobileContactButton = styled(Button)`
  margin-left: auto;
  margin-right: 3rem;

  ${largerThan.medium`
    display: none;
  `};
`

const Nav = ({ location }) => {
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = useCallback(() => {
    window.pageYOffset > 0 ? setIsScrolled(true) : setIsScrolled(false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Container role="navigation">
      <Wrapper>
        <Inner>
          <LogoWrap to="/" aria-label="Back to home">
            <StyledLogo />
          </LogoWrap>
          <List role="menubar">
            {/* <ListItem role="menuitem">
              <NavItem location={location} link="/ai/" label="AI" isNew />
            </ListItem> */}
            <ListItem role="menuitem">
              <NavItem location={location} link="/work/" label="Work" />
            </ListItem>

            <ListItem role="menuitem">
              <NavItem
                location={location}
                label="Services"
                sublinks={SERVICES.map((services, i) => {
                  return {
                    key: i,
                    label: services.name,
                    link: `/services/${services.slug}/`,
                    icon: services.icon,
                  }
                })}
              />
            </ListItem>

            <ListItem role="menuitem">
              <NavItem location={location} link="/about/" label="About" />
            </ListItem>

            <ListItem role="menuitem">
              <NavItem location={location} link="/careers/" label="Careers" />
            </ListItem>

            <ListItem role="menuitem">
              <NavItem location={location} link="/contact/" label="Contact" />
            </ListItem>
          </List>
          <MobileContactButton size="small" intent="tertiary" to="/contact/">
            Contact us
          </MobileContactButton>
          <MobileNav scrolled={isScrolled} location={location} />
        </Inner>
      </Wrapper>
    </Container>
  )
}

export default Nav
