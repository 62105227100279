import { css } from 'styled-components'

import { rgba } from 'polished'
import { black } from 'styles/colors'

// Vertical padding
export const verticalPadding = (padding) => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`

// Square
export const square = (size) => css`
  width: ${size};
  height: ${size};
`

export const boxShadow = {
  small: `box-shadow: 0 2px 4px 0 ${rgba(black, 0.05)}`,
  medium: `box-shadow: 0 3px 10px 0 ${rgba(black, 0.075)}`,
  large: `box-shadow: 0 5px 22px 0 ${rgba(black, 0.05)}`,
}

// Cover
export function cover(position) {
  return `
    position: ${position} !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}
