import React from 'react'
import styled from 'styled-components'
import { hideVisually, stripUnit } from 'polished'

import { animationTimes, animationCurve } from 'styles/variables'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Icon from 'components/Icon'

import GitHub from 'assets/icons/github.svg'
import LinkedIn from 'assets/icons/linkedin.svg'

const items = [
  {
    name: 'GitHub',
    icon: <GitHub />,
    link: 'https://github.com/vol7',
  },
  {
    name: 'LinkedIn',
    icon: <LinkedIn />,
    link: 'https://www.linkedin.com/company/volume7/',
  },
]

const Container = styled.div`
  display: inline-flex;
  margin: -${spacing.tiny};
`

const Item = styled.a`
  display: flex;
  transition: color ${animationTimes.base} ${animationCurve};
  padding: ${spacing.tiny};
  color: ${({ theme }) => theme.body};

  &:hover {
    color: ${({ theme }) => theme.heading};
  }

  & + & {
    margin-left: ${spacing.tiny};

    ${largerThan.small`
      margin-left: ${spacing.scale(spacing.tiny, 1.5)};
    `};
  }
`

const Label = styled.span`
  ${hideVisually()};
`

const SocialLinks = () => (
  <Container>
    {items.map((item, i) => (
      <Item key={i} href={item.link} target="_blank" rel="noopener noreferrer">
        <Icon icon={item.icon} />
        <Label>{item.name}</Label>
      </Item>
    ))}
  </Container>
)

export default SocialLinks
