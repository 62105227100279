import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Row, Col } from 'react-awesome-styled-grid'

import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'
import { customGridConfig } from 'styles/theme'

export const Grid = ({ children }) => (
  <ThemeProvider theme={{ awesomegrid: customGridConfig }}>
    {children}
  </ThemeProvider>
)

export const GridRow = styled(Row)`
  margin-bottom: -${spacing.large};

  ${largerThan.large`
    margin-bottom: -${spacing.xLarge};
  `};
`

export const GridColumn = styled(Col)`
  margin-bottom: ${spacing.large};

  ${largerThan.large`
    margin-bottom: ${spacing.xLarge};
  `};
`
